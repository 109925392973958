<template>
  <div class="">
    <h2>旭德简介</h2>
    <div v-if="aboutText.content" v-html="aboutText.content"></div>
  </div>
</template>
<script>
import { getContentInfo } from "@/api";
export default {
  name: "introduction",
  data() {
    return {
      aboutText: {},
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    async getAbout() {
      let res = await getContentInfo({ contentType: 4, type: 3 });
      console.log(res);
      if (res.code == 1) {
        this.aboutText = res.data || {};
      }
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  text-align: center;
  margin-bottom: 50px;
}
/deep/ img {
  width: 100% !important;
}
</style>